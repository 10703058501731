import React from 'react';
import Layout from '../components/layout/Layout';
import CV from '../images/ahmed-oulad-hadjou-cv.pdf';

const about = () => {
  return (
    <Layout pageTitle="About me">
      <div>
        <h1 className="section-title">About</h1>
        <p className="subtitle">About me</p>
        <hr className="horizontal-ruler" />
      </div>
      <div className="row">
        <div className="col-md-4 col-sm-12">
          <h3>About me</h3>
          <hr className="horizontal-ruler" />
          <p>
            I'm a full stack developer, I enjoy the process of making a web
            site/app from start to finish. This gives me the ability to keep an
            eye on the big picture the whole time I'm working on something.
          </p>
          <p>
            In my spare time, I enjoy running, reading books, eating healthy,
            watching Rick and Morty and spending time with friends.
          </p>
          <a href={CV} target="_blank" rel="noopener noreferrer">
            View my CV <i className="fas fa-external-link-alt" />
          </a>
        </div>
        <div className="col-md-3 col-sm-12">
          <h3>Tech I use</h3>
          <hr className="horizontal-ruler" />
          <h4 className="green-text">Front-end</h4>
          <p>React, Redux, Vanilla JS, SASS</p>
          <h4 className="green-text">Back-end</h4>
          <p>NodeJS, Laravel, APIs, MySQL, MongoDB</p>
          <h4 className="green-text">Dev ops</h4>
          <p>AWS, Firebase, Heroku, Server admin, Docker</p>
          <h4 className="green-text">Other</h4>
          <p>Gatsby, Next.JS, GraphQL, Ionic, WordPress, SEO</p>
        </div>
        <div className="col-md-5 col-sm-12">
          <h3>Books I read</h3>
          <hr className="horizontal-ruler" />
          <a
            rel="noopener noreferrer"
            href="https://www.goodreads.com/book/show/4845.Code_Complete"
            target="_blank"
          >
            Code Complete <i className="fas fa-external-link-alt" />
          </a>
          <p>
            by Steve McConnell ( Changed the way I write and document code )
          </p>
          <a
            rel="noopener noreferrer"
            href="https://www.goodreads.com/book/show/5479.Brave_New_World_Brave_New_World_Revisited"
            target="_blank"
          >
            Brave New World <i className="fas fa-external-link-alt" />
          </a>
          <p>by Aldous Huxley ( Brilliant dystopian novel )</p>
          <a
            rel="noopener noreferrer"
            href="https://www.goodreads.com/book/show/18197267-don-t-make-me-think-revisited"
            target="_blank"
          >
            Don't make me think <i className="fas fa-external-link-alt" />
          </a>
          <p>by Steve Krug ( Every UI designer should read this )</p>
          <a
            rel="noopener noreferrer"
            href="https://www.goodreads.com/book/show/4865.How_to_Win_Friends_and_Influence_People"
            target="_blank"
          >
            How to win friends and influence people{' '}
            <i className="fas fa-external-link-alt" />
          </a>
          <p>by Dale Carnagie ( this book should be taught at schools )</p>
          <a
            rel="noopener noreferrer"
            href="https://www.goodreads.com/hidjou"
            target="_blank"
          >
            More <i className="fas fa-external-link-alt" />
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-sm-12">
          <h3>Follow me</h3>
          <hr className="horizontal-ruler" />
          <a
            rel="noopener noreferrer"
            href="https://github.com/hidjou"
            target="_blank"
            className="follow-me-link"
          >
            <i className="fab fa-github fa-2x" />
          </a>
          <a
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/ahmed-hadjou-a7846885/"
            target="_blank"
            className="follow-me-link"
          >
            <i className="fab fa-linkedin fa-2x" />
          </a>
          <a
            rel="noopener noreferrer"
            href="https://www.youtube.com/classsed"
            target="_blank"
            className="follow-me-link"
          >
            <i className="fab fa-youtube fa-2x" />
          </a>
          <a
            rel="noopener noreferrer"
            href="https://www.goodreads.com/hidjou"
            target="_blank"
            className="follow-me-link"
          >
            <i className="fas fa-book fa-2x" />
          </a>
          <a
            rel="noopener noreferrer"
            href="https://dribbble.com/Hidjou"
            target="_blank"
            className="follow-me-link"
          >
            <i className="fab fa-dribbble fa-2x" />
          </a>
          <a
            rel="noopener noreferrer"
            href="https://twitter.com/TheHidjou"
            target="_blank"
            className="follow-me-link"
          >
            <i className="fab fa-twitter fa-2x" />
          </a>
          <a
            rel="noopener noreferrer"
            href="https://www.facebook.com/Hidjou"
            target="_blank"
            className="follow-me-link"
          >
            <i className="fab fa-facebook fa-2x" />
          </a>
          <a
            rel="noopener noreferrer"
            href="https://www.instagram.com/hahadjou/"
            target="_blank"
            className="follow-me-link"
          >
            <i className="fab fa-instagram fa-2x" />
          </a>
        </div>
        <div className="col-md-8 col-sm-12">
          <h3>Fun facts</h3>
          <hr className="horizontal-ruler" />
          <ul className="bullet-points">
            <li>
              <p>
                I follow a ketogenic diet (high fat, low carb) as I believe it's
                the best diet for the body and the brain.
              </p>
            </li>
            <li>
              <p>
                I believe that reading good books is an essensial part of being
                an educated person.
              </p>
            </li>
            <li>
              <p>
                Physical fitness brings mental fitness, that's why I regularly
                train at the gym and run at least 10 Kilometres a week.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default about;
